
@media (min-width: 992px) {
	/* We move the timeline border to the middle of the date entry */
.actualites .timeline-border {
		left:125px;
		margin-left:0px;
}

	/* We align the entry date section to the left */
.actualites .entry.entry-date-section {
		text-align: left;
}

	/* We center the text within the entry date */
.actualites .entry.entry-date-section span {
		text-align: center;
}

	/* We increase the space on the left to add entry date half size */
.actualites .post-timeline .entry.alt:not(.entry-date-section) {
		/* standard margin + 125px + 85px */
		padding-left: calc((var(--bs-gutter-x) * .5) + 125px + 85px);
}

	/* We set the position of the timeline link for each entry */
.actualites .entry .entry-timeline {
		display: block;
		/* Middle of the title */
		top: 12px;
		/* standard margin + 125px - 6px (half size of bullet) */
		left: calc((var(--bs-gutter-x) * .5) + 125px - 6px);
}

	/* We set custom size of the timeline divider */
.actualites .entry .entry-timeline .timeline-divider {
		width: 60px;
}
}

/* We set custom space between title and metas */
.actualites #posts .entry-meta {
	margin-top: 5px;
}

/* We set custom space between metas and picture */
.actualites #posts .display-default .entry-image {
	margin-top: 10px;
}

/* We ensure that entry image is not too big */
.actualites #posts .entry-image img {
	max-height: 400px;
	width:auto;
}

/* We reduce the space between post entries */
.actualites .post-timeline .entry {
	margin-bottom: 30px;
}
@media (max-width: 767.98px) {
	/* We reduce the space between content and post header */
.actualites #posts .entry-content {
		margin-top: 20px;
}

	/* We reduce the space after entry content */
.actualites #posts .entry-content p {
		margin-bottom: 20px;
}
}




